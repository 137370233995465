<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
  export default{
    name: 'App'
  }
</script>


<style lang="scss">
@import "assets/scss/style.scss";
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>