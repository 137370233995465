import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
// import store from "./store";
// import RouterPrefetch from "vue-router-prefetch";

Vue.config.productionTip = false;

Vue.use(router);
// Vue.use(RouterPrefetch);

  new Vue({
    router,
    // store,
    render: h => h(App),
    mounted() {}
  }).$mount("#app");